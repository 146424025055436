var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "skuId:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.skuId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "skuId", _vm._n($$v))
                  },
                  expression: "formData.skuId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "应用ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.appId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "appId", _vm._n($$v))
                  },
                  expression: "formData.appId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "类型:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.type,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "type", _vm._n($$v))
                  },
                  expression: "formData.type"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "券信息:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.info,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "info", $$v)
                  },
                  expression: "formData.info"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.status,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "status", _vm._n($$v))
                  },
                  expression: "formData.status"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单表主键:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.orderId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "orderId", _vm._n($$v))
                  },
                  expression: "formData.orderId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "过期时间:" } },
            [
              _c("el-date-picker", {
                attrs: { type: "date", placeholder: "选择日期", clearable: "" },
                model: {
                  value: _vm.formData.expireTime,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "expireTime", $$v)
                  },
                  expression: "formData.expireTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "导出记录:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.recordId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "recordId", _vm._n($$v))
                  },
                  expression: "formData.recordId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.back } },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }